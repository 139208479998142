.skeleton-display-text {
    &--fullwidth {
        .Polaris-SkeletonDisplayText__DisplayText {
            max-width: 100%;
            height: 32px;
        }
    }
}

.media-card-box {
    .Polaris-MediaCard {
        //min-height: 175px;
    }
}

.Polaris-Button {
    &--loading {
        .Polaris-Button__Text {
            opacity: 0;
        }
    }
}

.Polaris-Page {
    &--fullWidth {
        // padding-left: 0;
        // padding-right: 0;
    }
}

.Polaris-Frame__Main {
    max-width: unset;
}

.Polaris-Modal-Dialog__Modal {
    &.Polaris-Modal-Dialog--sizeFullScreen {
        width: 90%;
        max-width: unset;
        min-height: 90%;

        .Polaris-Modal__Body {
            min-height: calc(100vh - 113px);
        }
    }
}

.detail-table {
    width: 100%;

    tr {
        th {
            text-align: left;
            padding: 10px 20px;
        }

        td {
            font-size: 14px;
            border-top: 1px solid #dfdfdf;
            padding: 10px 20px;
            text-align: left;

            a {
                color: #006fbb;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.list-tags {
    margin-top: 10px;
    flex-wrap: wrap;

    .tag {
        background: #dfdfdf;
        border-radius: 40px;
        padding: 1px 7px;
        font-size: 11px;

        .Polaris-Icon {
            cursor: pointer;
        }
    }
}

.Polaris-Frame {
    min-height: auto !important;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    .Polaris-Frame__Main {
        border: none !important
    }
}